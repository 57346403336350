import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { scrollToTop } from '../../utils/methods';

const ScrollToTopHelper = ({location}) => {
  const [ nowLocal, setLocal ] = useState('');

  if (nowLocal !== location.pathname) {
    setLocal(location.pathname);
    scrollToTop(false);
  }

  return null;
}

export default withRouter(ScrollToTopHelper);