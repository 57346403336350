import React, { useEffect } from "react";
import { connect } from "react-redux";
import LoadingIcon from "../LoadingScreen/loading-icon";
import "./index.scss";

const LoadingScreen = ({ setLoadingState }) => {
  useEffect(() => {
    setLoadingState(true);
    return () => {
      setLoadingState(false);
    };
  }, []);

  return (
    <div className="loading-screen">
      <LoadingIcon />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoadingState: (state) =>
    dispatch({ type: "SET_LOADING_STATE", loading: state }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingScreen);

let init = {
  loading: false,
};

export const reducer = (state = init, action) => {
  switch (action.type) {
    case "SET_LOADING_STATE":
      return { ...state, loading: action.loading };

    default:
      return state;
  }
};
