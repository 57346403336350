import React, { Fragment } from "react";
import axios from "axios";
let _wording = null;

export const loadWordingJson = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        _wording = res.data;
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getWording = (fieldName) => {
  return _wording?.[fieldName] || fieldName;
};

export const nl2br = (str) => {
  if (str === null || str === undefined) return "";

  if (navigator.userAgent.match("MSIE") || navigator.userAgent.match(".NET")) {
    return str;
  }

  str = str.replace?.(/\\n/g, "\n");
  return str?.split?.("\n").map((item, key) => {
    return (
      <div
        key={"nl2br" + key}
        dangerouslySetInnerHTML={{
          __html: `<div>${item}</div>`,
        }}
      />
    );
  });
};

export const nl2p = (str) => {
  if (str === null || str === undefined) return "";
  str = str.replace?.(/\\n/g, "\n");

  return str?.split?.("\n").map((item, key) => {
    item = item !== "" ? item : <br />;
    if(String(item).indexOf('</a>')!=-1) return <p key={key} dangerouslySetInnerHTML={{ __html: item }}></p>;
    return <p key={key}>{item}</p>;
  });
};

export const co2_format = (str) => {
  if (str === null || str === undefined) return "";
  str = str.replace?.(/CO2/gi, "<CO2/>");
  const splitedArr = str?.split?.("<CO2/>");
  return splitedArr.map((item, key) => {
    return (
      <Fragment key={"co2" + key}>
        {
          <span>
            {item}
            {key < splitedArr.length - 1 && (
              <>
                CO
                <span style={{ verticalAlign: "sub", fontSize: "0.2em" }}>
                  2
                </span>
              </>
            )}
          </span>
        }
      </Fragment>
    );
  });
};
