import React from 'react';

export default () => (
  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 40 100 20" preserveAspectRatio="xMidYMid"
    className="loading-icon">
    <circle cx="84" cy="50" r="0" fill="#FFEA00">
      <animate attributeName="r" values="8;0;0;0;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5
        0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4.1s" repeatCount="indefinite" begin="0s"></animate>
      <animate attributeName="cx" values="84;84;84;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5
        1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4.1s" repeatCount="indefinite" begin="0s"></animate>
    </circle>
    <circle cx="16" cy="50" r="6.77133" fill="#71CC81">
      <animate attributeName="r" values="0;8;8;8;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5
        0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4.1s" repeatCount="indefinite" begin="-2.05s"></animate>
      <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5
        1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4.1s" repeatCount="indefinite" begin="-2.05s"></animate>
    </circle>
    <circle cx="84" cy="50" r="1.22867" fill="#088B48">
      <animate attributeName="r" values="0;8;8;8;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5
        0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4.1s" repeatCount="indefinite" begin="-1.025s"></animate>
      <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5
        1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4.1s" repeatCount="indefinite" begin="-1.025s"></animate>
    </circle>
    <circle cx="78.7781" cy="50" r="8" fill="#555555">
      <animate attributeName="r" values="0;8;8;8;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5
        0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4.1s" repeatCount="indefinite" begin="0s"></animate>
      <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5
        1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4.1s" repeatCount="indefinite" begin="0s"></animate>
    </circle>
    <circle cx="44.7781" cy="50" r="8" fill="#FFEA00">
      <animate attributeName="r" values="0;0;8;8;8" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5
        0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4.1s" repeatCount="indefinite" begin="0s"></animate>
      <animate attributeName="cx" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5
        1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4.1s" repeatCount="indefinite" begin="0s"></animate>
    </circle>
  </svg>
)