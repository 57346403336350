import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./index.scss";

class Breadcrumb extends Component {
  static pushRoutesInfo = (slug, label) => {
    this._routesInfo.push({ slug, label });
  };

  static setupTitlePrefix = (prefix) => {
    this._titlePrefix = prefix;
  };

  static _routesInfo = [];
  static _titlePrefix = "";

  findRouteInfo = (slug) => {
    return Breadcrumb._routesInfo.find((item) => item.slug === slug);
  };

  render() {
    const { match, lastLabel, onlyHelmet = false, helmetTitle } = this.props;
    const paths = ["", ...match.url.split("/").filter((item) => item !== "")];
    return (
      <div className="breadcrumb">
        <Helmet>
          <title>{`${
            helmetTitle ||
            lastLabel ||
            this.findRouteInfo(paths[paths.length - 1])?.label ||
            ""
          } | ${Breadcrumb._titlePrefix}`}</title>
        </Helmet>
        {!onlyHelmet &&
          paths?.map((path, index) => {
            const routeInfo = this.findRouteInfo(path);

            if (routeInfo || (lastLabel && index === paths.length - 1)) {
              const compProp = {
                className: "item",
                key: `bc${routeInfo?.slug || lastLabel}${index}`,
              };
              if (index < paths.length - 1) {
                const concatUrl = paths.reduce(
                  (acc, curr, currI) => acc + (currI <= index ? "/" + curr : "")
                );
                return (
                  <Link to={concatUrl} {...compProp}>
                    {routeInfo.label || path}
                  </Link>
                );
              } else if (index === paths.length - 1) {
                return (
                  <span {...compProp}>
                    {lastLabel || routeInfo.label || path}
                  </span>
                );
              }
            }
            return null;
          })}
      </div>
    );
  }
}
export default withRouter(Breadcrumb);
