import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Hamburger from "./Hamburger";
import { getWording } from "../../utils/wordingSystem";
import {
  MediaQueryDesktop,
  MediaQueryMobile,
} from "../../components/MediaQuery";
import Headroom from "react-headroom";

import "./index.scss";
import { getCookieType } from "../../utils/methods";

class Navbar extends Component {
  state = {
    overlayMenuOpen: false,
  };

  handleOverlayToggle = (enabled = null) => {
    const { overlayMenuOpen } = this.state;
    this.setState({
      overlayMenuOpen: enabled === null ? !overlayMenuOpen : enabled,
    });
  };

  render() {
    return (
      <>
        <MediaQueryDesktop>{this.navbarRender()}</MediaQueryDesktop>
        <MediaQueryMobile>
          <Headroom parent={() => document.querySelector("#content-frame")}>
            {this.navbarRender()}
          </Headroom>
        </MediaQueryMobile>
      </>
    );
  }

  navbarRender() {
    const { unit_name, office_name } = getWording("common");
    const menu_items = getWording("subjects");
    const { imageSrc } = getWording("nav_bar");
    const { overlayMenuOpen } = this.state;
    const { pageLoading, setModalShow } = this.props;

    const token = getCookieType("tycg_token");
    return (
      <nav className="navbar">
        <div
          className={"overlay-menu-mask" + (overlayMenuOpen ? " -open" : "")}
          onClick={() => this.handleOverlayToggle()}
        ></div>

        <div
          className={"navbar-inner" + (overlayMenuOpen ? " -open" : "")}
          onClick={() => this.handleOverlayToggle()}
        >
          <button className="vertical-layout">
            <Hamburger isOpen={overlayMenuOpen} />
            <div className="wording-deco">
              <img
                src="/img/nav-menu-deco.svg"
                alt="menu"
                className={"menu-deco-word" + (overlayMenuOpen ? " -open" : "")}
              />
              <img
                src="/img/nav-close-deco.svg"
                alt="menu"
                className={
                  "menu-deco-word -close-icon" +
                  (overlayMenuOpen ? " -open" : "")
                }
              />
            </div>
          </button>

          <button className="vertical-layout">
            <div className="navbar-wording -bold">{unit_name}</div>
            <div className="navbar-wording">{office_name}</div>
          </button>

          <div className="vertical-layout">
            <img
              src="/img/nav-bottom-deco.svg"
              alt="deco"
              className={"deco-icon" + (pageLoading ? " -loading" : "")}
            />
          </div>
        </div>

        <div className={"overlay-menu" + (overlayMenuOpen ? " -open" : "")}>
          <div
            className="img"
            style={{ backgroundImage: `url(${imageSrc})` }}
          ></div>
          <ul className="menu-list">
            {menu_items?.slice(0, -1).map?.((item) => (
              <li className="menu-item" key={item.label}>
                <NavLink
                  to={`/${item.slug}`}
                  onClick={() => {
                    this.handleOverlayToggle(false);
                  }}
                >
                  {item.label}
                </NavLink>
              </li>
            ))}

            {menu_items?.slice(-1).map?.((item) => (
              <li className="menu-item" key={item.label}>
                {token ? (
                  <NavLink
                    to={`/${item.slug.dashboard}`}
                    onClick={() => {
                      this.handleOverlayToggle(false);
                    }}
                  >
                    {item.label.dashboard}
                  </NavLink>
                ) : (
                  <a
                    className="nav-link"
                    onClick={() => {
                      this.handleOverlayToggle(false);
                      setModalShow(true);
                    }}
                  >
                    {item.label.login}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
