import React from "react";
import { Row } from "antd";

export const RenderLogin = ({
  loginForm,
  handleChangeMode,
  handleLoginSubmit,
  handleLoginForm,
}) => {
  return (
    <form id="register-login-form" onSubmit={handleLoginSubmit}>
      <label>帳號</label>
      <input
        type="text"
        id="username"
        name="username"
        placeholder="xxxxx@gmail.com"
        value={loginForm.account}
        onChange={(e) => handleLoginForm("account", e.target.value)}
        required
      />

      <label>密碼</label>
      <input
        type="password"
        id="password"
        name="password"
        placeholder="********"
        value={loginForm.password}
        onChange={(e) => handleLoginForm("password", e.target.value)}
        required
      />
      <div
        className="forget"
        style={{ float: "right" }}
        onClick={() => handleChangeMode("registerStepOne")}
      >
        註冊
      </div>
      <div className="forget" onClick={() => handleChangeMode("password")}>
        忘記密碼？
      </div>
      <input type="submit" value="登入" />
    </form>
  );
};

export const RenderRegisterStepOne = ({
  getRegisterToken,
  registerContent,
  handleRegisterForm,
  handleChangeMode,
}) => {
  return (
    <form
      id="register-login-form"
      className="form2"
      autoComplete="off"
      onSubmit={getRegisterToken}
    >
      <div style={{ width: "46%", float: "right" }}>
        <label>聯絡人姓名</label>
        <input
          type="other"
          id="contactname"
          name="contactname"
          value={registerContent.contactname}
          onChange={(e) => handleRegisterForm("contactname", e.target.value)}
          required
          autoComplete="off"
        />
        <label>聯絡人電話</label>
        <input
          type="other"
          id="phone"
          name="phone"
          value={registerContent.phone}
          onChange={(e) => handleRegisterForm("phone", e.target.value)}
          required
          autoComplete="off"
        />
        <label>聯絡人email</label>
        <input
          type="other"
          id="email"
          name="email"
          value={registerContent.email}
          onChange={(e) => handleRegisterForm("email", e.target.value)}
          required
          autoComplete="off"
        />
      </div>
      <div style={{ width: "46%" }}>
        <label>公司完整名稱</label>
        <input
          type="other"
          id="company"
          name="company"
          value={registerContent.company}
          onChange={(e) => handleRegisterForm("company", e.target.value)}
          required
          autoComplete="off"
        />
        <label>統一編號</label>
        <input
          type="other"
          id="taxnumber"
          name="taxnumber"
          value={registerContent.taxnumber}
          onChange={(e) => handleRegisterForm("taxnumber", e.target.value)}
          required
          autoComplete="off"
        />
        <label>電號</label>
        <input
          type="other"
          id="elenumber"
          name="elenumber"
          value={registerContent.elenumber}
          onChange={(e) => handleRegisterForm("elenumber", e.target.value)}
          required
          autoComplete="off"
        />
      </div>
      <div style={{ width: "100%" }}>
        <label>密碼</label>
        <input
          type="password"
          id="password"
          name="password"
          value={registerContent.password}
          onChange={(e) => handleRegisterForm("password", e.target.value)}
          required
        />
        <label>確認密碼</label>
        <input
          type="password"
          id="password"
          name="comfirmpassword"
          value={registerContent.confirmpassword}
          onChange={(e) =>
            handleRegisterForm("confirmpassword", e.target.value)
          }
          required
        />
      </div>
      <Row>
        {registerContent.password !== registerContent.confirmpassword && (
          <div className="danger" style={{ margin: "4px 0 10px 0" }}>
            請再次確認密碼是否一致
          </div>
        )}

        <div
          className="forget"
          style={{ marginLeft: "auto" }}
          onClick={() => handleChangeMode("login")}
        >
          返回登入
        </div>
      </Row>
      <input
        type="submit"
        id="register"
        name="register"
        style={{ marginTop: "2px" }}
        value="送出"
      />
    </form>
  );
};

export const RenderRegisterStepTwo = ({
  handleRegisterForm,
  registerContent,
  handleRegisterSubmit,
  handleChangeMode,
}) => {
  return (
    <form
      id="register-login-form"
      className="form3"
      autoComplete="off"
      onSubmit={handleRegisterSubmit}
    >
      <div>
        <label>驗證碼</label>
        <input
          type="other"
          id="token"
          name="token"
          placeholder="請輸入驗證碼"
          value={registerContent.token}
          onChange={(e) => handleRegisterForm("token", e.target.value)}
          required
          autoComplete="off"
        />
      </div>
      <Row>
        <div
          className="forget"
          style={{ marginLeft: "auto" }}
          onClick={() => handleChangeMode("registerStepOne")}
        >
          返回註冊
        </div>
      </Row>
      <input
        type="submit"
        id="register"
        name="register"
        style={{ marginTop: "2px" }}
        value="送出"
      />
    </form>
  );
};

export const RenderForgetPassword = ({
  handleForgetPassword,
  forgetPasswordContent,
  handleForgetPasswordSubmit,
  handleChangeMode,
}) => {
  return (
    <form
      id="register-login-form"
      className="form3"
      autoComplete="off"
      onSubmit={handleForgetPasswordSubmit}
    >
      <div>
        <label>帳號</label>
        <input
          type="other"
          id="forgetemail"
          name="forgetemail"
          placeholder="xxxx@gmail.com"
          value={forgetPasswordContent.email}
          onChange={(e) => handleForgetPassword("email", e.target.value)}
          required
          autoComplete="off"
        />
      </div>
      <Row>
        <div
          className="forget"
          style={{ marginLeft: "auto" }}
          onClick={() => handleChangeMode("login")}
        >
          返回登入
        </div>
      </Row>
      <input
        type="submit"
        id="register"
        name="register"
        style={{ marginTop: "2px" }}
        value="送出"
      />
    </form>
  );
};

export const ResetPassword = ({
  handleResetPassword,
  resetPasswordForm,
  handleResetPasswordSubmit,
  handleChangeMode,
}) => {
  return (
    <form
      id="register-login-form"
      className="form3"
      autoComplete="off"
      onSubmit={handleResetPasswordSubmit}
    >
      <div>
        <label>新密碼</label>
        <input
          type="password"
          id="password"
          name="newpassword"
          placeholder="xxxxxxxx"
          value={resetPasswordForm.password}
          onChange={(e) => handleResetPassword("password", e.target.value)}
          required
          autoComplete="off"
        />
        <label>請再次輸入密碼</label>
        <input
          type="password"
          id="password"
          placeholder="xxxxxxxx"
          name="confirmpassword"
          value={resetPasswordForm.confirmpassword}
          onChange={(e) =>
            handleResetPassword("confirmpassword", e.target.value)
          }
          required
          autoComplete="off"
        />
      </div>
      <Row>
        {resetPasswordForm.password !== resetPasswordForm.confirmpassword && (
          <div
            className="danger"
            style={{ margin: "4px 0 10px 0", fontSize: 12 }}
          >
            請再次確認密碼是否一致
          </div>
        )}

        <div
          className="forget"
          style={{ marginLeft: "auto" }}
          onClick={() => handleChangeMode("login")}
        >
          返回登入
        </div>
      </Row>
      <input
        type="submit"
        id="register"
        name="register"
        style={{ marginTop: "2px" }}
        value="送出"
      />
    </form>
  );
};
