import axios from "axios";
import { FILE_URL_DOMAIN } from "../../utils/define";
import { message } from "antd";
import { get } from "lodash";
const dashboardHost = FILE_URL_DOMAIN + "WS/api.asmx/";

export const Login = async (data) => {
  try {
    const token = await getLoginToken(data);
    document.cookie = `tycg_token=${token.success};expires=${new Date(
      token.exptime
    ).toUTCString()}`;
    document.cookie = `tycg_account=${data.account};expires=${new Date(
      token.exptime
    ).toUTCString()}`;

    Object.entries(token).forEach(([key, value]) => {
      if (key === "success" || key === "exptime") return;
      document.cookie = `tycg_${key}=${value};expires=${new Date(
        token.exptime
      ).toUTCString()};path=/`;
    });
    return { success: true };
  } catch (err) {
    console.log("err", err);
    message.error(err);
    return { success: false };
  }
};

const getLoginToken = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(dashboardHost + "poweruserlogin", data)
      .then((res) => {
        try {
          resolve(JSON.parse(res.data.d));
        } catch {
          reject(res.data.d);
        }
      })
      .catch((err) => {
        reject(get(err, "response.data.Message") || "錯誤");
      });
  });
};

export const registerDashboard = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(dashboardHost + "registerpoweruser", data)
      .then((res) => {
        if (res.data.d === "ok") {
          resolve();
        } else {
          reject(res.data.d);
        }
      })
      .catch((err) => {
        reject(get(err, "response.data.Message") || "錯誤");
      });
  });
};

export const sendPasswordEmail = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(dashboardHost + "sendpassmail", data)
      .then((res) => {
        if (res.data.d === "ok") {
          resolve();
        } else {
          reject(res.data.d);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(get(err, "response.data.Message") || "錯誤");
      });
  });
};
export const sendPassReset = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(dashboardHost + "sendpassreset", data)
      .then((res) => {
        if (res.data.d === "ok") {
          resolve();
        } else {
          reject(res.data.d);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(get(err, "response.data.Message") || "錯誤");
      });
  });
};

export const sendRegisterEmail = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(dashboardHost + "sendregistermail", data)
      .then((res) => {
        if (res.data.d === "ok") {
          resolve();
        } else {
          reject(res.data.d);
        }
      })
      .catch((err) => {
        console.log(err);
        reject("錯誤！");
      });
  });
};
