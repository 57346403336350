import { hot } from "react-hot-loader/root";
import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { getWording } from "../utils/wordingSystem";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { loadWordingJson } from "../utils/wordingSystem";
import ScrollToTopHelper from "../components/ScrollToTopHelper";
import LoadingScreen from "../components/LoadingScreen";
import LoginModal from "../components/LoginModal";
// import GAPageViewHelper from '../components/GAPageViewHelper';

import routes, { setupBreadcrumb, singlePage } from "../routes";

class App extends Component {
  state = {
    wordingLoading: false,
  };

  componentDidMount() {
    loadWordingJson("/wording/zh-TW.json").then(() => {
      this.setState({ wordingLoading: true });
      setupBreadcrumb();
    });
  }

  render() {
    const { wordingLoading } = this.state;
    const login = getWording("login");
    const isSinglePage =
      window.location.pathname.split("/").filter((item) => item !== "")[0] ===
      "single";
    if (wordingLoading) {
      return (
        <BrowserRouter>
          {isSinglePage ? (
            <Route
              render={({ location, history, match }) => {
                return (
                  <Suspense fallback={<LoadingScreen />}>
                    {singlePage({ location, history, match })}
                  </Suspense>
                );
              }}
            />
          ) : (
            <Route
              render={({ location, history, match }) => {
                return (
                  <div id="app">
                    <ScrollToTopHelper />
                    {/* <GAPageViewHelper/> */}
                    <Navbar />

                    <div id="content-frame">
                      <Switch key={location.key} location={location}>
                        <Suspense fallback={<LoadingScreen />}>
                          <main className="main">
                            {routes({ location, history, match })}
                          </main>
                        </Suspense>
                      </Switch>
                      <Footer />
                      <LoginModal {...login} />
                    </div>
                  </div>
                );
              }}
            />
          )}
        </BrowserRouter>
      );
    }

    return null;
  }
}

export default hot(App);
