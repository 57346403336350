import { animateScroll as scroll } from "react-scroll";
// import axios from 'axios';
import { oldApiRequest } from "../utils/api";
import { useEffect, useState, useRef } from "react";
const $ = window.$;

export const addSVGClass = (el, className) => {
  const classes = el.className.baseVal.split(" ");
  if (classes.find((c) => c === className) === undefined) {
    el.className.baseVal += " " + className;
  }
};

export const removeSVGClass = (el, className) => {
  el.className.baseVal = el.className.baseVal.replace(className, "");
};

export function shareToFB(extra) {
  if (extra === undefined) {
    extra = "";
  }
  window.open(
    "http://www.facebook.com/share.php?u=".concat(
      encodeURIComponent(window.location.href)
    )
  );
}

export function shareToLine() {
  var link = "http://line.naver.jp/R/msg/text/?";
  link += `%0D%0A${encodeURIComponent(window.location.href)}`;
  window.open(link);
  return false;
}

export function copyLink(e, onComplete = null) {
  copyToClipboard(window.location.href);
  onComplete && onComplete();
}

function copyToClipboard(text) {
  var temp = document.createElement("input");
  $("body").append(temp);
  temp.value = text;
  temp.select();
  document.execCommand("copy");
  temp.remove();
}

export const scrollToTop = (smooth = false) => {
  let options = {
    containerId: "content-frame",
    smooth: smooth,
    duration: smooth ? 700 : 0,
  };
  scroll.scrollToTop(options);
};

export const useApiToGetItemsAndCategories = (api_url, onError = null) => {
  const [list, setList] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      oldApiRequest
        .get(api_url) //REVISE: axios.get(api_url)
        .then((res) => {
          if (api_url == "/api/downloads") {
            let arr = [
              "第三型再生能源發電設備設備登記申請表",
              "再生能源發電設備同意備案申請表",
            ];
            let items = res.data.items.sort((a, b) => {
              return a.title.localeCompare(b.title);
            });
            items = items.sort((a, b) => {
              let index1 = arr.findIndex((key) => a.title.includes(key));
              let index2 = arr.findIndex((key) => b.title.includes(key));
              return index2 - index1;
            });
            setList(items);
          } else {
            setList(
              res.data.items.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )
            );
          }
          setCategories(res.data.categories);
        })
        .catch(onError);
    }, 1000);
  }, []);

  return [list, categories];
};

export const useScroll = (callback) => {
  const el = useRef(null);

  useEffect(() => {
    const scrollDom = document.querySelector("#content-frame");
    const scrollDomHeight = scrollDom.clientHeight;
    const elHeight = el.current.clientHeight;
    const allHeight = scrollDomHeight + elHeight;

    const handleScroll = (e) => {
      if (!el.current) return;
      const { top } = el.current.getBoundingClientRect();
      const calcedTop = top + elHeight;
      if (calcedTop > 0 && calcedTop < allHeight) {
        const prog = 1 - calcedTop / allHeight;
        callback(prog);
      }
    };

    scrollDom.addEventListener("scroll", handleScroll);
    return () => {
      scrollDom.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return el;
};

export const useIntersectionObserver = (callback, threshold) => {
  const el = useRef(null);

  useEffect(() => {
    let io = new IntersectionObserver(callback, {
      threshold: threshold,
    });

    io.observe(el.current);
    return () => {
      io.disconnect();
    };
  }, []);

  return el;
};

export const vhFix = () => {
  const injectVhToDocument = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  window.addEventListener("resize", () => {
    injectVhToDocument();
  });
  injectVhToDocument();
};

const formatFloat = (num, base = 100) => {
  return Math.round(num * base) / base;
};

export const numberWithCommas = (x = 0) => {
  let tmp = formatFloat(x).toString();
  return tmp.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function getCookieType(type) {
  const cookie = document.cookie.split(";").reduce((a, b) => {
    let [key, value] = b.split("=").map((str) => str.trim());
    if (key === "tycg_token") {
      value += "==";
    }
    a[key] = value;
    return a;
  }, {});
  return cookie[type] || "";
}
