import axios from "axios";

const newBaseURL = process.env.REACT_APP_API_NEW_HOST;
const oldBaseURL = process.env.REACT_APP_API_OLD_HOST;

export const oldApiRequest = axios.create({
  baseURL: oldBaseURL,
});

export const newApiRequest = axios.create({
  baseURL: newBaseURL,
});
