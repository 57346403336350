import { combineReducers } from "redux";

import fetchReducer from "../utils/fetchReducer";
import subjectOverlayNavigateReducer from "../components/SubjectOverlayNavigate/reducer";
import { reducer as loadingScreenReducer } from "../components/LoadingScreen";
import { reducer as loginModalReducer } from "../components/LoginModal";
// [AUTO_IMPORT_BY_ADD_COMPONENT];

export default combineReducers({
  fetchedData: fetchReducer,
  subjectOverlayNavigate: subjectOverlayNavigateReducer,
  loadingScreen: loadingScreenReducer,
  loginModal: loginModalReducer,
  // [AUTO_COMBINE_BY_ADD_COMPONENT],
});
