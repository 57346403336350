import React, { useEffect, useState } from "react";
import { message } from "antd";
import { connect } from "react-redux";
import "./index.scss";
import {
  Login,
  registerDashboard,
  sendPasswordEmail,
  sendPassReset,
  sendRegisterEmail,
} from "./action";
import { DashboardToken } from "../../utils/define";
import md5 from "md5";
import * as view from "./renderDiffTypeView";

const defaultLoginForm = { account: "", password: "" };
const defaultRegisterForm = {
  token: "",
  contactname: "",
  phone: "",
  email: "",
  company: "",
  taxnumber: "",
  elenumber: "",
  password: "",
  confirmpassword: "",
};
const defaultForgetPasswordForm = { email: "" };
const defaultResetPasswordForm = { password: "", confirmpassword: "" };

const LoginModal = ({
  title,
  img,
  modalShow,
  setModalShow,
  register,
  forgetPassword,
  resetPassword,
  sendRegisterCodeToEmail,
}) => {
  const [mode, setMode] = useState("login");
  const [loginForm, setLoginForm] = useState(defaultLoginForm);
  const [registerContent, setRegisterContent] = useState(defaultRegisterForm);
  const [forgetPasswordContent, setForgetPassword] = useState(
    defaultForgetPasswordForm
  );
  const [resetPasswordForm, setResetPassword] = useState(
    defaultResetPasswordForm
  );

  useEffect(() => {
    const isMobile = window.innerWidth < 992;
    if (window.location.pathname === "/dashboard/password") {
      setModalShow(true);
      setMode("reset");
      document.querySelector(".modal-content").style = `height: ${
        isMobile ? 465 : 500
      }px`;
    }
  }, []);

  const handleChangeMode = (val) => {
    document.getElementById("register-login-form").reset();
    setMode(val);
    const isMobile = window.innerWidth < 992;
    if (val === "login") {
      document.querySelector(".modal-content").style = `height: ${
        isMobile ? 420 : 465
      }px`;
    } else if (val === "registerStepOne") {
      document.querySelector(".modal-content").style = `height: ${
        isMobile ? 575 : 620
      }px`;
    } else {
      document.querySelector(".modal-content").style = `height: ${
        isMobile ? 390 : 450
      }px`;
    }
  };

  const handleLoginForm = (key, value) => {
    setLoginForm({ ...loginForm, [key]: value });
  };

  const handleForgetPassword = (key, value) => {
    setForgetPassword({ ...forgetPasswordContent, [key]: value });
  };

  const handleRegisterForm = (key, value) => {
    setRegisterContent({ ...registerContent, [key]: value });
  };

  const handleResetPassword = (key, value) => {
    setResetPassword({ ...resetPasswordForm, [key]: value });
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const { password, account } = loginForm;
    const token = DashboardToken;
    const { success } = await Login({
      password: md5(password),
      account,
      token,
    });
    if (success) {
      message.success("成功");
      window.location.href = "/dashboard";
    }
  };

  const handleForgetPasswordSubmit = async (e) => {
    e.preventDefault();
    const { email } = forgetPasswordContent;
    const token = DashboardToken;
    const { success } = await forgetPassword({ email, token });
    if (success) {
      message.success("請前往信箱查看");
      handleChangeMode("login");
      setForgetPassword({ email: "" });
    }
  };
  const getRegisterToken = async (event) => {
    event.preventDefault();
    const { email, password, confirmpassword } = registerContent;
    const token = DashboardToken;
    if (password !== confirmpassword) return;
    const { success } = await sendRegisterCodeToEmail({ token, email });
    if (success) {
      message.success("請前往信箱查看");
      handleChangeMode("registerStepTwo");
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    const {
      contactname,
      phone,
      email,
      company,
      taxnumber,
      elenumber,
      password,
      token,
    } = registerContent;

    const { success } = await register({
      a1: contactname,
      a2: email,
      a3: md5(password),
      a4: phone,
      a5: taxnumber,
      a6: elenumber,
      a7: company,
      token,
    });
    if (success) {
      handleChangeMode("login");
      setRegisterContent(defaultRegisterForm);
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmpassword } = resetPasswordForm;
    const token = window.location.search.split("token=")[1];
    if (password !== confirmpassword) return;

    const { success } = await resetPassword({ pass: md5(password), token });
    if (success) {
      message.success("成功");
      handleChangeMode("login");
      setForgetPassword({ password: "", confirmpassword: "" });
    }
  };

  const loginOrRegisterForm = () => {
    if (mode === "login") {
      return (
        <view.RenderLogin
          loginForm={loginForm}
          handleChangeMode={handleChangeMode}
          handleLoginSubmit={handleLoginSubmit}
          handleLoginForm={handleLoginForm}
        />
      );
    } else if (mode === "registerStepOne") {
      return (
        <view.RenderRegisterStepOne
          getRegisterToken={getRegisterToken}
          registerContent={registerContent}
          handleRegisterForm={handleRegisterForm}
          handleChangeMode={handleChangeMode}
        />
      );
    } else if (mode === "registerStepTwo") {
      return (
        <view.RenderRegisterStepTwo
          handleRegisterSubmit={handleRegisterSubmit}
          registerContent={registerContent}
          handleRegisterForm={handleRegisterForm}
          handleChangeMode={handleChangeMode}
        />
      );
    } else if (mode === "password") {
      return (
        <view.RenderForgetPassword
          handleForgetPasswordSubmit={handleForgetPasswordSubmit}
          forgetPasswordContent={forgetPasswordContent}
          handleForgetPassword={handleForgetPassword}
          handleChangeMode={handleChangeMode}
        />
      );
    } else {
      return (
        <view.ResetPassword
          handleResetPasswordSubmit={handleResetPasswordSubmit}
          resetPasswordForm={resetPasswordForm}
          handleResetPassword={handleResetPassword}
          handleChangeMode={handleChangeMode}
        />
      );
    }
  };
  return (
    <div id="myModal" className={`modal ${modalShow ? "-open" : ""}`}>
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={() => setModalShow(false)}>
            &times;
          </span>
          <h1>{title}</h1>
          <img alt="loading" src={img} />
        </div>
        <div className="modal-body">{loginOrRegisterForm()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  modalShow: state.loginModal.modalShow,
});

const mapDispatchToProps = (dispatch) => ({
  setModalShow: (state) =>
    dispatch({ type: "SET_LOGIN_MODAL_STATE", modalShow: state }),
  register: async (data) => {
    try {
      await registerDashboard(data);
      message.success("註冊成功，請等待審核開通");
      return { success: true };
    } catch (error) {
      message.error(error);
      return { success: false };
    }
  },

  forgetPassword: async (data) => {
    try {
      await sendPasswordEmail(data);
      return { success: true };
    } catch (error) {
      message.error(error);
      return { success: false };
    }
  },
  resetPassword: async (data) => {
    try {
      await sendPassReset(data);
      return { success: true };
    } catch (error) {
      message.error(error);
      return { success: false };
    }
  },
  sendRegisterCodeToEmail: async (data) => {
    try {
      await sendRegisterEmail(data);
      return { success: true };
    } catch (error) {
      message.error(error);
      return { success: false };
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);

let init = {
  modalShow: false,
};

export const reducer = (state = init, action) => {
  switch (action.type) {
    case "SET_LOGIN_MODAL_STATE":
      return { ...state, modalShow: action.modalShow };

    default:
      return state;
  }
};
