import React from 'react';
import './index.scss';

const Hamburger = ({ onClick, isOpen }) => (
  <i className={'hamburger' + (isOpen ? ' -open' : '')} onClick={onClick}>
    <i></i>
    <i></i>
    <i></i>
  </i>
)

export default Hamburger;
