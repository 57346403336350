import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Sandbox from './pure';

const mapStateToProps = (state, ownProps) => ({
  pageLoading: state.loadingScreen.loading
})

const mapDispatchToProps = (dispatch) => ({
  setModalShow: state => dispatch({ type: 'SET_LOGIN_MODAL_STATE', modalShow: state })
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Sandbox));
