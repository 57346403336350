let init = {
  // TODO: 此Component的Reducer state初始值
  
  prewSubjectSlug: ''
}

export default (state = init, action) => {
  switch (action.type) {

    // TODO: 在此新增與定義此Component的Reducer接收到Action後，所要回傳給Store的新State.

    case 'SET_PREW_SUBJECT_SLUG':
      return {...state, prewSubjectSlug: action.slug}

    default:
      return state
  }
}

// TODO: 定義完以上後，記得將此 Reducer Combine 進 `src/components/App/reducer.js` 中. (若你沒有使用auto combine的話)