import { connect } from 'react-redux';
import App from './pure';
// import { fetchData, getByKey } from '../fetchActions';

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
