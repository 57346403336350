// import "@babel/polyfill";
// import 'intersection-observer';

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { vhFix } from "./utils/methods";
// import ReactGA from 'react-ga';
// ReactGA.initialize("UA-147545928-1");
import * as serviceWorker from "./serviceWorker";

import App from "./App/index";
import appReducer from "./App/reducer";
import "./App.css";

// import ReactGA from "react-ga4";
// ReactGA.initialize("G-VPWW3CQS9H");

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-M7D9K9K'
}
 
TagManager.initialize(tagManagerArgs)

const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore);

const appStore = createStoreWithMiddleware(
  appReducer,
  typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <Provider store={appStore}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();

vhFix();
